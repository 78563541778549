import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaHeart } from "react-icons/fa";
import { IoIosHeart } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Paintball club SplatterZone
			</title>
			<meta name={"description"} content={"Experience the thrill, conquer the field - SplatterZone, where paintball adventures begin"} />
			<meta property={"og:title"} content={"Paintball club SplatterZone"} />
			<meta property={"og:description"} content={"Experience the thrill, conquer the field - SplatterZone, where paintball adventures begin"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} />
		</Helmet>
		<Components.Header />
		<Components.Hero background="linear-gradient(320deg,rgba(0,0,0,0) 0%,rgba(0, 0, 0, 0.76) 72.4%),--color-darkL2 url(https://uploads.quarkly.io/65e71741926d910020edb149/images/3-3.jpg?v=2024-03-06T10:22:29.442Z) center/cover">
			<Override slot="text">
				Prepare, strategize, and dominate the battlefield
			</Override>
			<Override slot="text1">
				Service Overview
			</Override>
		</Components.Hero>
		<Section padding="80px 0 0 0" quarkly-title="Cards-3">
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 2rem 0px"
				font="--headline2"
				text-align="center"
				md-margin="0px 0px 40px 0px"
				sm-font="normal 600 42px/1.2 --fontFamily-sans"
			>
				Adrenaline-Filled Battles
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="36px 34px"
				margin="0px 0px 0 0px"
				md-grid-template-columns="1fr"
				md-grid-gap="46px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					margin="50px 0px 0px 0px"
					md-margin="0 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/65e71741926d910020edb149/images/2-3.jpg?v=2024-03-06T10:22:29.441Z"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/65e71741926d910020edb149/images/2-3.jpg?v=2024-03-06T10%3A22%3A29.441Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/2-3.jpg?v=2024-03-06T10%3A22%3A29.441Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/2-3.jpg?v=2024-03-06T10%3A22%3A29.441Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/2-3.jpg?v=2024-03-06T10%3A22%3A29.441Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/2-3.jpg?v=2024-03-06T10%3A22%3A29.441Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/2-3.jpg?v=2024-03-06T10%3A22%3A29.441Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/2-3.jpg?v=2024-03-06T10%3A22%3A29.441Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#dcfc96"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="normal 500 28px/1.2 --fontFamily-sans">
							Standard Matches
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="--base" color="--dark">
						Engage in classic paintball skirmishes on our varied and dynamic fields, each offering unique tactical challenges.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					margin="80px 0px 0px 0px"
					md-margin="0 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/65e71741926d910020edb149/images/3-2.jpg?v=2024-03-06T10:22:29.447Z"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/65e71741926d910020edb149/images/3-2.jpg?v=2024-03-06T10%3A22%3A29.447Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/3-2.jpg?v=2024-03-06T10%3A22%3A29.447Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/3-2.jpg?v=2024-03-06T10%3A22%3A29.447Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/3-2.jpg?v=2024-03-06T10%3A22%3A29.447Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/3-2.jpg?v=2024-03-06T10%3A22%3A29.447Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/3-2.jpg?v=2024-03-06T10%3A22%3A29.447Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/3-2.jpg?v=2024-03-06T10%3A22%3A29.447Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#c5fcff"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="normal 500 28px/1.2 --fontFamily-sans">
							Scenario Games
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="--base" color="--dark">
						Immerse yourself in themed scenarios that transport you to thrilling worlds, from post-apocalyptic wastelands to covert spy missions.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 0 0" quarkly-title="Cards-3">
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 2rem 0px"
				font="--headline2"
				text-align="center"
				md-margin="0px 0px 40px 0px"
				sm-font="normal 600 42px/1.2 --fontFamily-sans"
			>
				Equipment Rental
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="36px 34px"
				margin="0px 0px 60px 0px"
				md-grid-template-columns="1fr"
				md-grid-gap="46px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					margin="50px 0px 0px 0px"
					md-margin="0 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/65e71741926d910020edb149/images/4-3.jpg?v=2024-03-06T10:22:29.435Z"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-3.jpg?v=2024-03-06T10%3A22%3A29.435Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-3.jpg?v=2024-03-06T10%3A22%3A29.435Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-3.jpg?v=2024-03-06T10%3A22%3A29.435Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-3.jpg?v=2024-03-06T10%3A22%3A29.435Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-3.jpg?v=2024-03-06T10%3A22%3A29.435Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-3.jpg?v=2024-03-06T10%3A22%3A29.435Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-3.jpg?v=2024-03-06T10%3A22%3A29.435Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#dcfc96"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="normal 500 28px/1.2 --fontFamily-sans">
							Markers and Accessories
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="--base" color="--dark">
						Arm yourself with our high-quality paintball markers and accessories to be ready for any battle.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					margin="80px 0px 0px 0px"
					md-margin="0 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/65e71741926d910020edb149/images/2-2.jpg?v=2024-03-06T10:22:29.435Z"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
						object-position="top"
						srcSet="https://smartuploads.quarkly.io/65e71741926d910020edb149/images/2-2.jpg?v=2024-03-06T10%3A22%3A29.435Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/2-2.jpg?v=2024-03-06T10%3A22%3A29.435Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/2-2.jpg?v=2024-03-06T10%3A22%3A29.435Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/2-2.jpg?v=2024-03-06T10%3A22%3A29.435Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/2-2.jpg?v=2024-03-06T10%3A22%3A29.435Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/2-2.jpg?v=2024-03-06T10%3A22%3A29.435Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/2-2.jpg?v=2024-03-06T10%3A22%3A29.435Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#c5fcff"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="normal 500 28px/1.2 --fontFamily-sans">
							Protective Gear
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="--base" color="--dark">
						Ensure your safety and

 comfort with our selection of protective gear, including masks, vests, and padding.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="0 0 0 0" sm-padding="60px 0 60px 0" quarkly-title="Images-6">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			/>
			<Box
				display="flex"
				width="45%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					width="100%"
					height="auto"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 125% 0px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						top={0}
						right={0}
						src="https://uploads.quarkly.io/65e71741926d910020edb149/images/3-1.jpg?v=2024-03-06T10:22:29.432Z"
						display="block"
						width="100%"
						bottom={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						left={0}
						srcSet="https://smartuploads.quarkly.io/65e71741926d910020edb149/images/3-1.jpg?v=2024-03-06T10%3A22%3A29.432Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/3-1.jpg?v=2024-03-06T10%3A22%3A29.432Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/3-1.jpg?v=2024-03-06T10%3A22%3A29.432Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/3-1.jpg?v=2024-03-06T10%3A22%3A29.432Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/3-1.jpg?v=2024-03-06T10%3A22%3A29.432Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/3-1.jpg?v=2024-03-06T10%3A22%3A29.432Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/3-1.jpg?v=2024-03-06T10%3A22%3A29.432Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				display="flex"
				width="55%"
				flex-direction="column"
				height="100%"
			>
				<Box
					height="auto"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 45% 0px"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						display="block"
						top="auto"
						right={0}
						bottom="0px"
						src="https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"
						object-fit="cover"
						left={0}
						min-height="100%"
						position="absolute"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10%3A22%3A29.434Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10%3A22%3A29.434Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10%3A22%3A29.434Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10%3A22%3A29.434Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10%3A22%3A29.434Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10%3A22%3A29.434Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10%3A22%3A29.434Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					position="relative"
					padding="0px 0px 45% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					height="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					margin="1rem 0px 0px 0px"
				>
					<Image
						object-fit="cover"
						width="100%"
						bottom="0px"
						min-height="100%"
						src="https://uploads.quarkly.io/65e71741926d910020edb149/images/1-2.jpg?v=2024-03-06T10:22:29.476Z"
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
						srcSet="https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-2.jpg?v=2024-03-06T10%3A22%3A29.476Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-2.jpg?v=2024-03-06T10%3A22%3A29.476Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-2.jpg?v=2024-03-06T10%3A22%3A29.476Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-2.jpg?v=2024-03-06T10%3A22%3A29.476Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-2.jpg?v=2024-03-06T10%3A22%3A29.476Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-2.jpg?v=2024-03-06T10%3A22%3A29.476Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-2.jpg?v=2024-03-06T10%3A22%3A29.476Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" quarkly-title="Advantages/Features-21">
			<Override slot="SectionContent" max-width="1100px" sm-min-width="280px" />
			<Box
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="64px"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-gap="48px"
			>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
						<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
							<Box
								width="100%"
								height="100%"
								position="absolute"
								top="0px"
								left="0px"
								background="#dcfc96"
								opacity="0.5"
								border-radius="50%"
							/>
							<Icon
								category="fa"
								icon={FaHeart}
								color="#dcfc96"
								size="48px"
								padding="6px 6px 6px 6px"
								position="static"
							/>
						</Box>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Team-Building Events
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Whether it's a birthday party, bachelor/bachelorette celebration, or just a fun day out with friends, our group packages ensure an unforgettable experience.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="--color-primary"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="io"
							icon={IoIosHeart}
							color="--primary"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
							border-color="--color-primary"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Training and Coaching
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Take your skills to the next level with advanced training sessions focused on tactics, teamwork, and game awareness.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							opacity="0.5"
							border-radius="50%"
							background="#c5fcff"
						/>
						<Icon
							category="fa"
							icon={FaHeart}
							color="#c5fcff"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Private Events
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Enjoy exclusive amenities and services tailored to your needs, including catering options and dedicated staff assistance.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65dde6288e2e8e002178b6f4"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});